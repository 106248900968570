import React from "react"

import Quotes from "../components/quotes"
import Navigation from "../components/navigation"
import Footer from "../components/footer"
import Intro from "../components/intro"

const Layout = props => {
  const isBrowser = typeof window !== "undefined"
  // eslint-disable-next-line no-use-before-define
  const [darkTheme, setDarkTheme] = React.useState(getDefaultTheme())

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (isBrowser) {
      window.localStorage.setItem("dark", JSON.stringify(darkTheme))
    }
  }, [darkTheme, isBrowser])

  function getDefaultTheme() {
    if (isBrowser) {
      return JSON.parse(window.localStorage.getItem("dark"))
    }

    return false
  }

  const { location, children } = props

  const pageClass = location.pathname === "/" ? "homePage" : "otherPage"

  const toggleDarkMode = () => {
    setDarkTheme(prevTheme => !prevTheme)
  }

  return (
    <div className={darkTheme ? "dark-theme" : "light-theme"}>
      <Navigation darkTheme={darkTheme} clicked={toggleDarkMode} />
      <main className={pageClass}>
        <aside>
          <Intro />
          <Quotes />
        </aside>
        <section id="contentArea" className="contentArea">
          {children}
        </section>
      </main>
      <Footer />
    </div>
  )
}

export default Layout

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Experience = props => {
  const title = "Meghna Srivastava"
  const page = {}
  page.title = "Experience"
  page.content = `<h2>Experience</h2>
<div class="experience">

<p>Software Engineer/ Frontend Developer at <a href="https://www.auto1-group.com">AUTO1 Group</a>, Berlin</p>
<p>January 2019 &#8211; present</p>
<p>Frontend Web Developer - Internat <a href="https://qvsta.com">QVSTA GmbH</a>, Berlin</p>
<p>September 2018 &#8211; December 2018</p>
<p>Dental Surgeon/ Medical Writer, India</p>
<p>2013 &#8211; 2018</p>
</div>
<h2>Education</h2>
<div class="education">
<p>Web Development Courses</p>
<p>Udemy, FreeCodecamp - (2017 - 2018) </p>
<p>Bachelor of Dental Surgery</p>
<p>B.R Ambedkar University, India - (2013)</p>
</div>
<a class="button button__secondary" href="https://docs.google.com/document/d/1ltZXUktjBZFGRv3ic4R4YBtJ9V11-kmQsbbsRNOVDiw/edit?usp=sharing" target="_blank"><button style="all: unset">Check my resume</button></a></p>`

  return (
    <Layout location={props.location} title={title}>
      <SEO title="Experience" />
      <div
        dangerouslySetInnerHTML={{ __html: page.content }}
        className="experience"
      ></div>
    </Layout>
  )
}

export default Experience

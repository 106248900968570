import React from "react"

const Intro = () => {
  return (
    <div className="intro">
      <h1>
        Front-End
        <br />
        Web Developer,
      </h1>
      <h2>
        JavaScript enthusiast,
        <br />
        diving deep in React
      </h2>
      <p>
        My name is Meghna and I’m a <u>dentist turned web developer</u>. I'm
        currently working with AUTO1 Group in Berlin, Germany. I like
        developing user-friendly interactive web-apps by using React. Recently,
        I have started writing in TypeScript and prefer it over JavaScript. I also
        have an interest in public speaking, and aim to become an advocate for
        inclusion and diversity in the tech industry.
      </p>
      <div className="intro__buttonGroup">
        <a href="/about/" className="button button__primary">
          Learn more about me
        </a>
        <a href="/projects/" className="button button__secondary">
          Check out my projects
        </a>
      </div>
    </div>
  )
}

export default Intro

import React from "react"

const pickQuote = () => {
  const quotes = [
    "Take ownership they said, it will be fun they said",

    "Code never lies, comments sometimes do.",

    "There's no place like 127.0.0.1",

    "One of the best programming skills is knowing when to walk away for a while.",

    "Instead of a justify-content center, or flex-end... I need a justify-content: flex-slightly-past-two-thirds.",

    "I'm a front-end developer who wants to become a full-stack, I mean funsie developer.",

    "Now I'm having inspiration for my portfolio site and having design regrets... like food regret, but design regret.",

    "I'm so stressed solving a bug that only chicken or pizza can solve my issues.",

    "Some days I really wish you could traverse up the DOM in CSS.",

    "Me: Why is my JavaScript not working? Also me: using wrong syntax",

    "My CSS is so complex it disgusts me.",
  ]

  const random = Math.floor(Math.random() * quotes.length)
  return quotes[random]
}
const Quotes = () => {
  return (
    <div className="footer__quotes">
      <h3>Meggie's quote of the page:</h3>
      <p>&quot;{pickQuote()}&quot;</p>
    </div>
    // <div className="footer__quotes">
    //   <a
    //     class="twitter-timeline"
    //     href="https://twitter.com/hellomeghna?ref_src=twsrc%5Etfw"
    //   >
    //     Tweets by hellomeghna
    //   </a>
    // </div>
  )
}

export default Quotes

import React from "react"

const Footer = () => {
  return (
    <footer>
      <div className="footer__fullWidth">
        <div className="footer__right">
          <nav className="footer__nav">
            <ul>
              <li>
                <a href="https://twitter.com/hellomeghna">Twitter</a>
              </li>

              <li>
                <a href="https://github.com/meghna-saxena">GitHub</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/hellomeghna">LinkedIn</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="footer__copyright">
          Developed by Meghna Srivastava - an experiment with{" "}
          <a href="https://www.gatsbyjs.org">Gatsby</a>| ©{" "}
          {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  )
}

export default Footer
